interface Coordinates {
    latitude: number;
    longitude: number;
}

interface CalculateDistanceParams {
    positionA: Coordinates;
    positionB: Coordinates;
}

// lifted from StackOverflow: https://stackoverflow.com/a/18883819

/**
 *
 * @returns Distance between two positions in miles
 */
export const calculateDistanceBetweenCoordinates = ({ positionA, positionB }: CalculateDistanceParams) => {
    const EARTH_RADIUS_MILES = 3959;
    const latitudeDistance = degreesToRadians(positionB.latitude - positionA.latitude);
    const longitudeDistance = degreesToRadians(positionB.longitude - positionA.longitude);
    const latitudeARadians = degreesToRadians(positionA.latitude);
    const latitudeBRadians = degreesToRadians(positionB.latitude);

    const a =
        Math.sin(latitudeDistance / 2) * Math.sin(latitudeDistance / 2) +
        Math.sin(longitudeDistance / 2) *
            Math.sin(longitudeDistance / 2) *
            Math.cos(latitudeARadians) *
            Math.cos(latitudeBRadians);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = EARTH_RADIUS_MILES * c;
    return distance;
};

// Converts numeric degrees to radians
const degreesToRadians = (degrees) => (degrees * Math.PI) / 180;
