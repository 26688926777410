import { format, parse } from 'date-fns';

export const parseAndFormatDate = (inputDate: string) => {
    if (!inputDate) {
        return '';
    }

    const parsedDate = parse(inputDate, 'yyyy-MM-dd HH:mm:ss', new Date());
    return format(parsedDate, 'MM/dd/yy h:mm a');
};

export const calculateKeyServicesCount = (tasks: RefusedTask[]): number => {
    const uniqueKeyServices = new Set(tasks.map((task) => task.keyService));
    return uniqueKeyServices.size;
};

const sortTasksByLength = (
    a: PRNTasksPerTaskName | RefusedTasksPerKeyService,
    b: PRNTasksPerTaskName | RefusedTasksPerKeyService
) => b.tasks.length - a.tasks.length;

export const createRefusedTasksPerKeyService = (tasks: RefusedTask[]): RefusedTasksPerKeyService[] => {
    const uniqueKeyServices = new Set(tasks.map((task) => task.keyService));

    const refusedTasksPerKeyService: RefusedTasksPerKeyService[] = [];

    uniqueKeyServices.forEach((keyService) =>
        refusedTasksPerKeyService.push({
            keyService,
            tasks: tasks.filter((task: { keyService: string }) => task.keyService === keyService),
        })
    );

    const sortedRefusedTasksPerKeyService = [...refusedTasksPerKeyService].sort(sortTasksByLength);

    return sortedRefusedTasksPerKeyService;
};

export const createGroupedPRNTasks = (tasks: PRNTask[]): PRNTasksPerTaskName[] => {
    const uniqueTaskNames = new Set(tasks.map((task) => task.taskName));

    const groupedPRNTasks: PRNTasksPerTaskName[] = [];

    uniqueTaskNames.forEach((taskName) =>
        groupedPRNTasks.push({
            taskName,
            tasks: tasks.filter((task: { taskName: string }) => task.taskName === taskName),
        })
    );

    const sortedGroupedPRNTasks = [...groupedPRNTasks].sort(sortTasksByLength);

    return sortedGroupedPRNTasks;
};

export const createTasksPerDate = (tasks: RefusedTask[] | PRNTask[]): RefusedTasksPerDate[] => {
    const uniqueDates = new Set(tasks.map((task: RefusedTask | PRNTask) => task.shiftDayDate));

    const tasksPerDate: RefusedTasksPerDate[] | PRNTasksPerDate[] = [];

    uniqueDates.forEach((date) =>
        tasksPerDate.push({
            date,
            tasks: tasks.filter((task: { shiftDayDate: string }) => task.shiftDayDate === date),
        })
    );

    return tasksPerDate;
};

export const getCaregiversFromTasks = (tasks: (RefusedTask | PRNTask)[]): CaregiverData[] =>
    tasks.reduce<CaregiverData[]>((acc, task) => {
        const { caregiver } = task;
        if (caregiver) {
            const existingCaregiver = acc.find(
                (existing) => existing.firstName === caregiver.firstName && existing.lastName === caregiver.lastName
            );

            if (!existingCaregiver) {
                acc.push(caregiver);
            }
        }
        return acc;
    }, []);
