import { Box } from '@mui/system';
import React from 'react';

export const FormattedText = ({ text, bold, underline }: { text: string; bold?: number; underline?: boolean }) => (
    <Box
        component="span"
        sx={{
            fontWeight: bold || 'inherit',
            textDecoration: underline ? 'underline' : 'none',
        }}
    >
        {text}
    </Box>
);
